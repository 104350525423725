import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore/lite';
import emailjs from 'emailjs-com'; // Import the emailjs librar
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Logo from "./logo1.png";
import axios from 'axios';
import { PDFDocument, rgb } from 'pdf-lib';

import { PDFViewer, pdf } from '@react-pdf/renderer';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import timesNewRomanFont from './NimbusMono-Regular.otf';

Font.register({ family: 'Times New Roman', src: timesNewRomanFont });

const numberToWords = (num) => {
  const ones = [
    'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
    'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen',
  ];
  const tens = [
    '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety',
  ];
  const scales = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];

  const convertChunkToWords = (num) => {
    if (num === 0) return '';
    if (num < 20) return ones[num];
    if (num < 100) return `${tens[Math.floor(num / 10)]} ${convertChunkToWords(num % 10)}`.trim();
    return `${ones[Math.floor(num / 100)]} Hundred ${convertChunkToWords(num % 100)}`.trim();
  };

  if (num === 0) return 'Zero';
  if (num < 0 || isNaN(num)) return 'Invalid number';

  let chunkIndex = 0;
  let words = '';

  while (num > 0) {
    const chunk = num % 1000;
    if (chunk !== 0) {
      const chunkWords = `${convertChunkToWords(chunk)} ${scales[chunkIndex]}`.trim();
      words = `${chunkWords} ${words}`.trim();
    }
    num = Math.floor(num / 1000);
    chunkIndex++;
  }

  return words;
};



const firebaseConfig = {
  apiKey: "AIzaSyCGMDhQP-EAnFmlR9r1hFeZB1S4BFpc3rQ",
  authDomain: "sam-hyper-business-682ea.firebaseapp.com",
  projectId: "sam-hyper-business-682ea",
  storageBucket: "sam-hyper-business-682ea.appspot.com",
  messagingSenderId: "707629335149",
  appId: "1:707629335149:web:1edbe7ab6c476cee02f564",
  measurementId: "G-WLYSXR186X"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);



export default function SalaryAndtax() {
  const [daysInMonth, setDaysInMonth] = useState(null);
  const currentDate = new Date();

  // Get the current month and year
  const [currentMonth, setCurrentmonth] = useState("")
  // const currentYear = currentDate.getFullYear();
  const [currentYear, setCurrentYear] = useState(Date().slice(11, 15))
  const getDaysInMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Month is zero-indexed (0 for January), so we add 1

    // Use the Date constructor to get the last day of the next month and then extract the last day of the current month
    const lastDayOfCurrentMonth = new Date(year, month, 0).getDate();
    setDaysInMonth(daysInMonth);
  };
  useEffect(() => {
    // Function to calculate the number of days in the current month
    getDaysInMonth();

    getDaysInMonth();
  }, []);
  const formatDate = (inputDate) => {
    const dateParts = inputDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    console.log(day / month / year)
    return `${day}/${month}/${year}`;
  };
  const generateSalarySlipPDF = ((employee) => {
    const letter = parseInt(getNetSalary(employee))
    return (
      <Document  >
        <Page size="A4" style={styles.page}>
          <View style={styles.fullborder}>
            <View style={styles.lola}>
              {/* Display the image at the top left */}
              <View>
                <Image style={styles.logo} src={Logo} />
                <View style={styles.il}>
                  <View style={styles.ill}>
                  </View>
                  <View style={styles.ill}>
                  </View>
                  <Text style={styles.companyName}>SAM HYPER BUSINESS PRIVATE LIMITED</Text>
                  <Text style={styles.title}>SALARY SLIP FOR {currentMonth.toUpperCase()} {currentYear}</Text>
                </View>
              </View>

              <View style={styles.labelw} >
                <Text style={styles.labelq}>
                  Plot No. 22, First Floor,Chennai Green City,
                </Text>
                <Text style={styles.labelq}>Thiruverkadu Main Road, Paruthipattu,   </Text>
                <Text style={styles.labelq}>Chennai 600071.
                </Text>
                <Text style={styles.labelq}>PH: 044 - 35743419, 044 - 47757459. </Text>

              </View>




            </View>

            <View style={styles.divider} />


            <View style={styles.lola}>
              <View style={styles.lol}>

                <View style={styles.row}>
                  <Text style={styles.label}>EMPLOYEE NO</Text>
                  <Text style={styles.value}>: {employee.employeeId}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>EMPLOYEE NAME</Text>
                  <Text style={styles.value}>: {employee.fullName}</Text>
                </View>

                <View style={styles.row}>
                  <Text style={styles.label}>D. O. J.</Text>
                  <Text style={styles.value}>: {formatDate(employee.employmentStartDate)} </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>DEPARTMENT</Text>
                  <Text style={styles.value}>: {employee.department} </Text>
                </View>

                <View style={styles.row}>
                  <Text style={styles.label}>DESIGNATION</Text>
                  <Text style={styles.value}>: {employee.jobTitle} </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>LOCATION</Text>
                  <Text style={styles.value}>: {employee.location} </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>PF NUMBER </Text>
                  <Text style={styles.value}>: {employee.pfNumber} </Text>
                </View>

              </View>





              <View style={styles.lol}>


                <View style={styles.row}>
                  <Text style={styles.label}>PAN</Text>
                  <Text style={styles.value}>: {employee.pan}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>UAN NUMBER</Text>
                  <Text style={styles.value}>: {employee.uan}</Text>
                </View>

                <View style={styles.row}>
                  <Text style={styles.label}>BANK NAME</Text>
                  <Text style={styles.value}>: {employee.bankName}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>BANK A/C NO</Text>
                  <Text style={styles.value}>: {employee.bankAccountNumber}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>MONTH DAYS</Text>
                  <Text style={styles.value}>: <Text>{daysInMonth}</Text><Text>.00</Text></Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>DAYS PAYABLE</Text>
                  <Text style={styles.value}>: <Text>{daysInMonth - Math.max(getAbsentDays((employee.attendance) - 2), 0)}</Text><Text>.00</Text></Text>
                </View>

                <View style={styles.row}>
                  <Text style={styles.label}>LOP DAYS</Text>
                  <Text style={styles.value}>:  {Math.max(getAbsentDays((employee.attendance) - 2), 0)}.00</Text>
                </View>

              </View>

            </View>
            <View style={styles.paddingtop}></View>
            <View style={styles.divider} />


            <View style={styles.lola}>

              <View style={styles.row}>
                <Text style={styles.label}>PARTICULARS</Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>  EARNINGS </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>|</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>PARTICULARS</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>DEDUCTIONS</Text>
              </View>

            </View>
            <View style={styles.divider} />

            <View style={styles.lola}>

              <View style={styles.row}>
                <Text style={styles.label}>BASIC PAY</Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}> <Text>{parseInt(employee.salary)}</Text> <Text>{(employee.salary % 1 !== 0) ? `.${(employee.salary % 1).toFixed(2).slice(2)}` : ".00"}</Text></View>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>|</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>P.T </Text>
              </View>
              <View style={styles.row}>
                <View style={styles.label}> <Text>{parseInt(employee.pt)}</Text> <Text>{(employee.pt % 1 !== 0) ? `.${(employee.pt % 1).toFixed(2).slice(2)}` : ".00"}</Text></View>
              </View>

            </View>


            <View style={styles.lola}>

              <View style={styles.row}>
                <Text style={styles.label}>H.R.A</Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}> <Text>{parseInt(employee.hra)}</Text> <Text>{(employee.hra % 1 !== 0) ? `.${(employee.hra % 1).toFixed(2).slice(2)}` : ".00"}</Text></View>

              </View>
              <View style={styles.row}>
                <Text style={styles.label}>|</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>P.F</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.label}> <Text>{parseInt(employee.pf)}</Text> <Text>{(employee.pf % 1 !== 0) ? `.${(employee.pf % 1).toFixed(2).slice(2)}` : ".00"}</Text></View>

              </View>

            </View>
            <View style={styles.lola}>

              <View style={styles.row}>
                <Text style={styles.label}>SPL - ALL</Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}> <Text>{parseInt(employee.specialallowance)}</Text> <Text>{(employee.specialallowance % 1 !== 0) ? `.${(employee.specialallowance % 1).toFixed(2).slice(2)}` : ".00"}</Text></View>

              </View>
              <View style={styles.row}>
                <Text style={styles.label}>|</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>E.S.I </Text>
              </View>
              <View style={styles.row}>
                <View style={styles.label}> <Text>{parseInt(employee.esi)}</Text> <Text>{(employee.esi % 1 !== 0) ? `.${(employee.esi % 1).toFixed(2).slice(2)}` : ".00"}</Text></View>

              </View>

            </View>

            <View style={styles.lola}>

              <View style={styles.row}>
                <Text style={styles.label}>CONVEYANCE</Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}> <Text>{parseInt(employee.incentive)}</Text> <Text>{(employee.incentive % 1 !== 0) ? `.${(employee.incentive % 1).toFixed(2).slice(2)}` : ".00"}</Text></View>

              </View>
              <View style={styles.row}>
                <Text style={styles.label}>|</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>L.O.P</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.label}> <Text>{parseInt(getAbsentDays(employee.attendance) > 2 ? parseFloat(((employee.salary / daysInMonth) * (getAbsentDays(employee.attendance) - 2)).toFixed(2)) : 0)}</Text> <Text>{((getAbsentDays(employee.attendance) > 2 ? parseFloat(((employee.salary / daysInMonth) * (getAbsentDays(employee.attendance) - 2)).toFixed(2)) : 0) % 1 !== 0) ? `${(getAbsentDays(employee.attendance) > 2 ? parseFloat(((employee.salary / daysInMonth) * (getAbsentDays(employee.attendance) - 2)).toFixed(2)) : 0 % 1).toFixed(2).slice(2)}` : ".00"}</Text></View>


              </View>

            </View>


            <View style={styles.lola}>

              <View style={styles.row}>
                <Text style={styles.label}>BONOUS</Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}> <Text>{parseInt(employee.bonous)}</Text> <Text>{(employee.bonous % 1 !== 0) ? `.${(employee.bonous % 1).toFixed(2).slice(2)}` : ".00"}</Text></View>

              </View>
              <View style={styles.row}>
                <Text style={styles.label}>|</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}></Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}></Text>
              </View>

            </View>


            <View style={styles.divider} />

            <View style={styles.lola}>

              <View style={styles.row}>
                <Text style={styles.label}>GROSS EARNINGS </Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}> <Text>{(parseFloat(employee.salary) + parseFloat(employee.incentive) + parseFloat(employee.hra) + parseFloat(employee.specialallowance) + parseFloat(employee.bonous)).toFixed(2)}</Text> </View>

              </View>
              <View style={styles.row}>
                <Text style={styles.label}>|</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>TOTAL DEDUCTIONS</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.label}> <Text>{(parseFloat(employee.salary) + parseFloat(employee.incentive) + parseFloat(employee.hra) + parseFloat(employee.specialallowance) + parseFloat(employee.bonous) - parseFloat(getNetSalary(employee))).toFixed(2) || 0}</Text> </View>

              </View>

            </View>
            <View style={styles.divider} />

            <View style={styles.lola}>

              <View style={styles.row}>
                <Text style={styles.label}>NET PAY</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}></Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}></Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}></Text>
              </View>
              <View style={styles.row}>
                <View style={styles.label}> <Text>{parseInt(getNetSalary(employee))}</Text> <Text>{(getNetSalary(employee) % 1 !== 0) ? `.${(getNetSalary(employee) % 1).toFixed(2).slice(2)}` : ".00"}</Text></View>

              </View>

            </View>


            <View style={styles.divider} />
            <Text style={styles.font}>{numberToWords(letter)} . {numberToWords((getNetSalary(employee) % 1 !== 0) ? `${(getNetSalary(employee) % 1).toFixed(2).slice(2)}` : "")}</Text>
            <View style={styles.divider} />

            <View style={styles.paddingtop}></View>
            <View style={styles.paddingtop}></View>
            <View style={styles.paddingtop}></View>

            <Text style={styles.fonta} >This is a computer generated document, hence no signature is required</Text>
          </View>
        </Page>

      </Document>
    )
  })

  const styles = StyleSheet.create({
    fullborder: {
      flex: 1,
      borderWidth: 1,
      padding: 10,
      borderColor: 'black', // Set the border color to black
      borderStyle: 'solid', // Set the border style to dashed
    },
    il: {
      marginTop: 25,
    },
    paddingtop: {
      marginTop: 0,
    },
    lola: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    lol: {
      width: '50%',
    },
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      padding: 20,
    },
    ill: {
      padding: 5
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 0,
      fontSize: 10,
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },
    label: {
      width: '50%',
      textAlign: 'left',
      display: "flex",
      flexDirection: 'row',
      // justifyContent: 'space-between',
      justifyContent: "flex-end",
      fontSize: 10,
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },
    labelq: {
      width: '100%',
      textAlign: 'left',
      fontSize: 10,
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },
    labelw: {
      width: '100%',
      left: 120,
      top: 20,
      textAlign: 'left',
      fontSize: 10,
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },
    labela: {
      width: '50%',
      textAlign: 'left',
      fontSize: 10,
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },
    value: {
      width: '50%',
      textAlign: 'left',
      fontSize: 10,
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },
    margintop: {
      marginTop: 0,
    },
    font: {
      fontSize: 10,
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },
    fonta: {
      fontSize: 10,
      top: 20,
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },

    logo: {
      position: 'absolute',
      left: 0,
      width: 80,
      height: 40,
      top: 0,
      marginTop: 0,
      bottom: 10
    },
    header: {
      marginBottom: 0,
    },
    companyName: {
      fontSize: 10,
      fontWeight: 'normal', // Set the font weight to normal (lite)
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
      top: 3,
      fontWeight: 'bold',
    },
    title: {
      fontSize: 10,
      fontWeight: 'normal', // Set the font weight to normal (lite)
      color: 'black', // Set the text color to a light color
      marginBottom: 1,
      top: 3,
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },
    divider: {
      borderBottomWidth: 1,
      borderBottomColor: 'black', // Set the border color to a light color
      borderStyle: 'dashed', // Set the border style to dashed
      marginTop: 1,
      marginBottom: 5,
    },
    section: {
      marginBottom: 10,
      fontSize: 10,
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },
    earningsSection: {
      flexDirection: 'row',
      marginBottom: 5,
    },
    headerText: {
      fontWeight: 'normal', // Set the font weight to normal (lite)
      marginRight: 10,
      flex: 1,
      fontSize: 10,
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },
    deductionsSection: {
      flexDirection: 'row',
      marginBottom: 5,
    },
    totalRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
    netPayRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 5,
      marginBottom: 5,
    },
    amountInWords: {
      marginTop: 5,
      marginBottom: 5,
      fontSize: 10,
      color: 'black', // Set the text color to a light color
      fontFamily: 'Times New Roman', // Set the font family to Times New Roman
    },
  });


  // const lol=async()=>{
  //   const storageRef = ref(storage, "pdfs/ppt.pdf"); 
  //   let blob = await pdf(generateSalarySlipPDF()).toBlob();
  //   uploadBytes(storageRef, blob).then((snapshot) => {
  //     console.log("Uploaded a blob or file!", snapshot);
  //    });

  // }


  const [employeeData, setEmployeeData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchByName, setSearchByName] = useState('');
  const [searchById, setSearchById] = useState('');
  const [searchByPhone, setSearchByPhone] = useState('');


  const getNetSalary = (employee) => {
    console.log("net salary : " + employee)
    const pf = parseFloat(employee.pf) || 0;
    const pt = parseFloat(employee.pt) || 0;
    const esi = parseFloat(employee.esi) || 0;
    const incentive = parseFloat(employee.incentive) || 0;
    const baseSalary = parseFloat(employee.salary) || 0;
    const hra = parseFloat(employee.hra) || 0;
    const specialallowance = parseFloat(employee.specialallowance) || 0;
    const bonous = parseFloat(employee.bonous) || 0;

    // Calculate the total number of absent days for the employee
    const absentDays = getAbsentDays(employee.attendance);

    // Calculate the loss of pay for absent days (if more than 2 days)
    const lossOfPay = absentDays > 2 ? parseFloat(((employee.salary / daysInMonth) * (absentDays - 2)).toFixed(2)) : 0;
    // Calculate the net salary by subtracting pf, pt, esi, adding the incentive, and deducting the loss of pay
    return baseSalary - pf - pt - esi + incentive - lossOfPay + hra + specialallowance + bonous;
  };
  const [salaryDetails, setSalaryDetails] = useState([]);
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        // const employeeCol = collection(db, 'employeeDetails');
        // const employeeSnapshot = await getDocs(employeeCol);
        // const employees = employeeSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const response = await axios.get('https://dremerz.net/api/employees/');
        setEmployeeData(response.data);
        console.log("--------employeedata------------------")
        setLoading(false);
      } catch (error) {
        console.error('Error fetching employee data:', error);
        setError('Error fetching employee data. Please try again.');
        setLoading(false);
      }
    };

    const fetchAttendanceData = async () => {
      try {
        // const attendanceCol = collection(db, 'employeeAttendance');
        // const attendanceSnapshot = await getDocs(attendanceCol);
        // const attendanceData = attendanceSnapshot.docs.map((doc) => doc.data());
        const response = await axios.get('https://dremerz.net/api/attendance/');
        setAttendanceData(response.data);
        console.log("-----------attendancedata---------------")
      } catch (error) {
        console.error('Error fetching attendance data:', error);
        setError('Error fetching attendance data. Please try again.');
      }
    };
    const fetchSalaryDetails = async () => {
      try {
        // const salaryDetailsCol = collection(db, 'salaryDetails');
        // const salarySnapshot = await getDocs(salaryDetailsCol);
        // const details = salarySnapshot.docs.map((doc) => doc.data());
        const response = await axios.get('https://dremerz.net/api/salary/');
        setSalaryDetails(response.data);
        console.log("-----------salarydetails---------------")
      } catch (error) {
        console.error('Error fetching salary details:', error);
      }
    };

    fetchSalaryDetails();

    fetchSalaryDetails();
    fetchEmployeeData();
    fetchAttendanceData();
  }, []);

  // Function to calculate the number of present days for a specific employee
  const getPresentDays = (employeeId) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return attendanceData.filter((data) => {
      const attendanceDate = new Date(data.date); // Assuming the date is stored in the 'date' field
      const attendanceMonth = attendanceDate.getMonth();
      const attendanceYear = attendanceDate.getFullYear();

      return (
        data.employeeid === employeeId &&
        data.attendance === 'present' &&
        attendanceMonth === currentMonth &&
        attendanceYear === currentYear
      );
    }).length;
  };

  // Function to calculate the number of absent days for a specific employee
  const getAbsentDays = (attendance) => {
    // const currentDate = new Date();
    // const currentMonth = currentDate.getMonth();
    // const currentYear = currentDate.getFullYear();

    // return attendanceData.filter((data) => {
    //   const attendanceDate = new Date(data.date); // Assuming the date is stored in the 'date' field
    //   const attendanceMonth = attendanceDate.getMonth();
    //   const attendanceYear = attendanceDate.getFullYear();

    //   return (
    //     data.employeeid === employeeId &&
    //     data.attendance === 'absent' &&
    //     attendanceMonth === currentMonth &&
    //     attendanceYear === currentYear
    // );
    // }).length;
    return (attendance)
  };


  // Function to calculate the total days for a specific employee
  const getTotalDays = (employeeId) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return attendanceData.filter((data) => {
      const attendanceDate = new Date(data.date); // Assuming the date is stored in the 'date' field
      const attendanceMonth = attendanceDate.getMonth();
      const attendanceYear = attendanceDate.getFullYear();

      return (
        data.employeeid === employeeId &&
        attendanceMonth === currentMonth &&
        attendanceYear === currentYear
      );
    }).length;
  };


  const filteredEmployeeData = employeeData.filter(
    (employee) =>
      employee.fullName.toLowerCase().includes(searchByName.toLowerCase()) &&
      (searchById === '' || employee.employeeId.includes(searchById)) &&
      (searchByPhone === '' || (employee.phoneNumber && employee.phoneNumber.includes(searchByPhone)))
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handlePaySalary = async () => {
    const confirmed = window.confirm('Are you sure you want to pay salaries?');
    if (confirmed) {
      try {
        // Loop through filteredEmployeeData and add salary details to the 'salaryDetails' collection one by one
        for (const employee of filteredEmployeeData) {
          const netSalary = getNetSalary(employee);
          const salaryData = {
            fullName: employee.fullName,
            employeeId: employee.employeeId,
            phoneNumber: employee.phoneNumber,
            salary: employee.salary,
            pt: employee.pt,
            pf: employee.pf,
            esi: employee.esi,
            incentive: employee.incentive,
            absentDays: getAbsentDays(employee.attendance),
            presentDays: getPresentDays(employee.employeeId),
            totalDays: getTotalDays(employee.employeeId),
            netSalary: netSalary,
            lossOfPayAmount: getAbsentDays(employee.attendance) > 2 ? parseFloat(((employee.salary / daysInMonth) * (getAbsentDays(employee.attendance) - 2)).toFixed(2)) : 0,
          };
          const storageRef = ref(storage, `salary_slips/${employee.employeeId + currentDate}.pdf`);




          let blob = await pdf(generateSalarySlipPDF(employee)).toBlob();

          const formData = new FormData();
          formData.append("pdfFile", blob, "pdfs/mfile.pdf");
          formData.append("password",employee.employeeId);
          console.log("dremerz",formData);

          // Send a POST request to your Flask API
          const response = await fetch("https://samhyperbusiness.org/pdf/custom-route/", {
            method: "POST",
            body: formData,
          });

          console.log("dremerz",response);
          // Check if the response status is OK (status code 200)
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Read the response as a Blob
         let  bloba = await response.blob();
    

          uploadBytes(storageRef, bloba).then((snapshot) => {
            console.log("Uploaded a blob or file!", snapshot);
          });




          const pdfRef = ref(storage, `salary_slips/${employee.employeeId + currentDate}.pdf`);
          const downloadURL = await getDownloadURL(pdfRef);

          // Send the email with the download link of the PDF
          const serviceID = 'service_1vakeah'; // Replace with your Email.js service ID
          const templateID = 'template_0fj788i'; // Replace with your Email.js template ID
          const userID = 'yuK_tXt2bSt5wElYE'; // Replace with your Email.js user ID

          const maildata = {
            emailAddress: employee.emailAddress,
            fullName: employee.fullName,
            pf: employee.pf,
            pt: employee.pt,
            esi: employee.esi,
            salary: employee.salary,
            netSalary: netSalary,
            incentive: employee.incentive,
            pdfDownloadURL: downloadURL,
          };

          await emailjs.send(serviceID, templateID, maildata, userID);
        }

        alert('Salary details have been saved and emails with PDFs have been sent successfully.');
      } catch (error) {
        console.error('Error saving salary details or sending emails:', error);
        alert('Error saving salary details or sending emails. Please try again.');
      }
    } else {
      alert('Salary payment has been canceled.');
    }
  };






  const getPaymentCount = (employeeId) => {
    return salaryDetails.filter((detail) => detail.employeeId === employeeId).length;
  };

  const calculateHistory = (employeeId, field) => {
    let total = 0;
    for (const detail of salaryDetails) {
      if (detail.employeeId === employeeId) {
        const fieldValue = parseFloat(detail[field]) || 0;
        total += fieldValue;
      }
    }
    return total;
  };
  const months = [
    'Select',
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  return (
    <div className="dashboard-content-one" id="cont-1">
      <div className="breadcrumbs-area">
        <h3>Salary & Tax Details</h3>
      </div>
      <div style={{ display: "flex" }}>
        <div className="col-3-xxxl col-xl-3 col-lg-3 col-12 form-group">
          Enter the N.O days in Month.
          <input
            type="number"
            placeholder="Enter the days in Month ..."
            className="form-control"
            value={daysInMonth}
            onChange={(e) => setDaysInMonth((e.target.value))}
          />
        </div>
        <div className="col-3-xxxl col-xl-3 col-lg-3 col-12 form-group">
          <label>Select Month:</label>
          <select
            className="form-control"
            value={currentMonth}
            onChange={(e) => setCurrentmonth(e.target.value)}
          >
            {months.map((month, index) => (
              <option key={index} value={month}>
                {month}
              </option>
            ))}
            {/* Add other months here */}
          </select>

        </div>

        <div className="col-3-xxxl col-xl-3 col-lg-3 col-12 form-group">
          <label>Select Year :</label>
         <input type="number" placeholder="Enter the Year ..." className="form-control" value={currentYear} onChange={(e) => setCurrentYear(e.target.value)} />

        </div>
      </div>
      <div className="card height-auto">
        <div className="card-body">
          <div className="heading-layout1"></div>

          <form className="mg-b-20">
            <div className="row gutters-8">
              <div className="col-4-xxxl col-xl-4 col-lg-3 col-12 form-group">
                <input
                  type="text"
                  placeholder="Search by Name ..."
                  className="form-control"
                  value={searchByName}
                  onChange={(e) => setSearchByName(e.target.value)}
                />
              </div>
              <div className="col-3-xxxl col-xl-3 col-lg-3 col-12 form-group">
                <input
                  type="text"
                  placeholder="Search by ID ..."
                  className="form-control"
                  value={searchById}
                  onChange={(e) => setSearchById(e.target.value)}
                />
              </div>
              <div className="col-4-xxxl col-xl-3 col-lg-3 col-12 form-group">
                <input
                  type="text"
                  placeholder="Search by Phone ..."
                  className="form-control"
                  value={searchByPhone}
                  onChange={(e) => setSearchByPhone(e.target.value)}
                />
              </div>
              <div className="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
                <button
                  type="button"
                  style={{ textAlign: "center" }}
                  className="fw-btn-fill btn-gradient-yellow"
                >
                  SEARCH
                </button>
              </div>
            </div>
          </form>
          <div className="table-responsive">

            <table className="table display data-table text-nowrap">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Full Name</th>
                  <th>Employee ID</th>
                  <th>Phone Number</th>
                  <th>Bank Name</th>
                  <th>Bank Account Number</th>
                  <th>IFSC Code</th>
                  <th>Salary</th>
                  <th>CONVEYANCE</th>
                  <th>H.R.A</th>
                  <th>SPECIAL ALLOWANCE</th>
                  <th>BONOUS</th>
                  <th>PT</th> {/* New column for displaying PT */}
                  <th>PF</th> {/* New column for displaying PF */}
                  <th>ESI</th> {/* New column for displaying ESI */}
                  <th>Loss of Pay Amount</th>
                  <th>Net Salary</th> {/* New column for displaying Net Salary */}
                  <th>L.O.P days</th>
                  <th>Absent days</th>
                  {/* <th>Present days</th>
                  <th>Total days</th> */}
                  <th>History of Total Salary</th>
                  <th>History of Total CONVEYANCE</th>
                  <th>History of P.F</th>
                  <th>History of P.T</th>
                  <th>History of E.S.I</th>
                  <th>History of Net Salary</th>
                  <th>Payment Count</th>
                </tr>
              </thead>
              <tbody>
                {/* Loop through filteredEmployeeData */}
                {filteredEmployeeData.length ? (
                  filteredEmployeeData.map((employee, index) => (
                    <tr key={employee.id}>
                      <td>{index + 1}</td>
                      <td>{employee.fullName}</td>
                      <td>{employee.employeeId}</td>
                      <td>{employee.phoneNumber || 'N/A'}</td>
                      <td>{employee.bankName || 'N/A'}</td>
                      <td>{employee.bankAccountNumber || 'N/A'}</td>
                      <td>{employee.ifscCode || 'N/A'}</td>
                      <td style={{ color: "blue" }}>{employee.salary || 'N/A'}</td>
                      <td style={{ color: "blue" }} blue>{employee.incentive || 'N/A'}</td>
                      <td style={{ color: "blue" }} blue>{employee.hra || 'N/A'}</td>
                      <td style={{ color: "blue" }} blue>{employee.specialallowance || 'N/A'}</td>
                      <td style={{ color: "blue" }} blue>{employee.bonous || 'N/A'}</td>
                      <td style={{ color: "red" }}>{employee.pt || 'N/A'}</td>
                      <td style={{ color: "red" }}>{employee.pf || 'N/A'}</td>
                      <td style={{ color: "red" }}>{employee.esi || 'N/A'}</td>
                      <td style={{ color: "red" }}>{getAbsentDays(employee.attendance) > 2 ? parseFloat(((employee.salary / daysInMonth) * (getAbsentDays(employee.attendance) - 2)).toFixed(2)) : 0}</td>
                      <td style={{ color: "green" }} >{getNetSalary(employee)}</td> {/* Display the net salary */}
                      <td>{Math.max(getAbsentDays((employee.attendance) - 2), 0)}</td>
                      <td>{getAbsentDays(employee.attendance)}</td>
                      {/* <td>{getPresentDays(employee.employeeId)}</td>
                      <td>{getTotalDays(employee.employeeId)}</td> */}
                      <td style={{ color: "blue" }}>{calculateHistory(employee.employeeId, 'salary') || 'N/A'}</td>
                      <td style={{ color: "blue" }}>{calculateHistory(employee.employeeId, 'incentive') || 'N/A'}</td>
                      <td style={{ color: "red" }} >{calculateHistory(employee.employeeId, 'pf') || 'N/A'}</td>
                      <td style={{ color: "red" }} >{calculateHistory(employee.employeeId, 'pt') || 'N/A'}</td>
                      <td style={{ color: "red" }} >{calculateHistory(employee.employeeId, 'esi') || 'N/A'}</td>
                      <td style={{ color: "green" }}>{calculateHistory(employee.employeeId, 'netSalary') || 'N/A'}</td>
                      <td>{getPaymentCount(employee.employeeId)}</td> {/* Display the payment count */}



                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12">No matching employees found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <br></br>
          <div style={{ textAlign: 'right' }}>

            {
              (daysInMonth) ?
                <button type="button" onClick={handlePaySalary} className="fw-btn-fill btn-gradient-yellow">
                  Pay Salary
                </button>
                : "Enter N.O Days.."
            }

          </div>
        </div>
      </div>



    </div>
  );
}
